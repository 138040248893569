


body {
  font-size: .875rem;
  background-color: #f7f7f7
}

label {
  margin-bottom: 0;
}

#infoBorder{
  border-width: 3px !important;
  border-color: rgb(5, 98, 166) !important;
}

.bg-primary, .btn-primary {
  background-color: $brand-color !important;
}

.dropdown-menu {
  background-color: $brand-color;
  border: none;
  
  @media (max-width: 576px) {
    margin: 0;
    padding: 0;
  }

  .dropdown-item {
    color:rgba(255, 255, 255, 0.5);
  }

  .dropdown-item:hover, .dropdown-item.selected {
      color: white;
      text-decoration: none;
      background-color: inherit;
  }
}

.dropdown-toggle::after {
    transition: transform 0.5s;
  } 

  .dropdown.show .dropdown-toggle::after {
    transform: rotate(180deg);
  }

/*
 * Sidebar
 */

 @media (min-width: 768px) { 
  .navbar-nav.custom-menu {
    display:none !important
  }
}

.hideRender{
  display:none
}

.nav-item.selected .nav-link {
  color: rgba(255, 255, 255, 1);
}

.sidebar {
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 48px; /* Height of navbar */
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar-submenu ul li{
  cursor:pointer
}

.sidebar-dropdown a{
  cursor:pointer
}

.sidebar .nav-link.active {
  color: #0562a6;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

/*
 * Navbar
 */

.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
}

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

.form-row label {
  margin-bottom: auto;
  margin-top: auto;
}

/*
 * Utilities
 */

.border-top { border-top: 1px solid #e5e5e5; }
.border-bottom { border-bottom: 1px solid #e5e5e5; }

.sidebar-wrapper .sidebar-menu .sidebar-dropdown > a {
  content: "\f105";
}

.spacer {
  border-top: 1px solid #dee2e6;
  visibility: hidden;
}

#ReporteKilogramos {
  border: 1px solid #dee2e6;
}

#ReporteKilogramos td, #ReporteKilogramos th {
  text-align: center;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(124, 155, 255, 0.15);
}

.table-responsive{max-height:600px;overflow:auto;} 
thead tr:nth-child(1) th{background: white; position: sticky;top: 0;z-index: 10;}

.banner-bg {
  background-image: url(/img/banner.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home {
  font-size:16px;

  img {
    height: 80px;
    width: auto;
  }
  span {
    display: block;
    width: 100%;
  }

  span.url{
    list-style-type: square;
    display: list-item;
  }

  span.url:hover {
    cursor: pointer;
    text-decoration: underline;  
  }

  .item {
    box-shadow: 0 2px 3px rgba(0,0,0,0.3);
    background-color: white;
    transition: transform .2s;
    /* min-height: 250px; */
    border-radius: 10px;
    border: #818181 solid 2px;
  }

  .item:hover{
    transform: scale(1.05);
  }

  .title {
    font-size:25px;
    text-align:center;
    text-transform: uppercase;

    @media (max-width: 768px) {
      font-size:20px;
    }
  }

  .title:hover{
    cursor: pointer;
    text-decoration: underline;
  }

  .title::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: middle;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    transition: transform 0.5s;
  } 

  .title.open::after {
    transform: rotate(180deg);
  }
}

.navbar-nav {
  li, button{
    font-size: 18px;
  }
}

.list-group {
  cursor: pointer;
}

.list-group.list-group-root {
  padding: 0;
  overflow: hidden;
  border: 1px solid black;
}

.list-group.list-group-root > .list-group-item-action {
  border-bottom:  1px solid black !important;
}
 
.list-group.list-group-root .list-group {
  margin-bottom: 0;
}

.list-group.list-group-root .list-group-item {
  border-radius: 0;
  border-width: 1px 0 0 0;
}

.list-group.list-group-root > .list-group-item:first-child {
  border-top-width: 0;
}

.list-group.list-group-root > .list-group > .list-group-item {
  padding-left: 30px;
}

.list-group.list-group-root > .list-group > .list-group > .list-group-item {
  padding-left: 45px;
}

.list-group-item .fa {
  margin-right: 5px;
}

.collapse {
  display: none;
  &.show {
    display: block;
  }
}