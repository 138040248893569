*{
    margin:0;
   }
   
   #container{
       display: flex;
       flex-wrap: wrap;
       height: 95%;
       z-index: -2;
   }

   
   .abrirButtonPrograma{
     height:35px
   }
  
   
   .verticaltext{
     margin: auto;
     width: 1.75rem;
     word-wrap: break-word;
     white-space:pre-wrap; 
   }
   

   