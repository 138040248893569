@import "./variables.scss";

.configBox {
  overflow: hidden;
  margin-bottom: 15px;

  > div {
    border: 0.5px $brand-color solid;
    text-align: center;
  }
}

.title {
  position: relative;
  z-index: 2;
  background: $brand-color;
  height: 30px;

  h3 {
    display: inline-block;
    margin: auto;
    z-index: 2;
    color: white;
    font-size: 20px;
  }
}

.content {
  position: relative;
  background: white;
  /* height: 71%; */
  z-index: 1;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 10px;

  padding-top: 10px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2), 0 0px 26px 0 rgba(0, 0, 0, 0.19);  

  input {
    margin-left: 10px;
    width: 100%;
  }
}

